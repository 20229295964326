<template>
  <CDropdown color="black" placement="bottom-end" :caret="false">
    <template #toggler-content>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        aria-hidden="true"
        class="svg-inline--fa fa-globe fa-w-16"
        data-icon="globe"
        data-prefix="fal"
        viewBox="0 0 496 512"
      >
        <path
          fill="currentColor"
          d="M248 8C111 8 0 119 0 256s111 248 248 248 248-111 248-248S385 8 248 8zm193.2 152h-82.5c-9-44.4-24.1-82.2-43.2-109.1 55 18.2 100.2 57.9 125.7 109.1zM336 256c0 22.9-1.6 44.2-4.3 64H164.3c-2.7-19.8-4.3-41.1-4.3-64s1.6-44.2 4.3-64h167.4c2.7 19.8 4.3 41.1 4.3 64zM248 40c26.9 0 61.4 44.1 78.1 120H169.9C186.6 84.1 221.1 40 248 40zm-67.5 10.9c-19 26.8-34.2 64.6-43.2 109.1H54.8c25.5-51.2 70.7-90.9 125.7-109.1zM32 256c0-22.3 3.4-43.8 9.7-64h90.5c-2.6 20.5-4.2 41.8-4.2 64s1.5 43.5 4.2 64H41.7c-6.3-20.2-9.7-41.7-9.7-64zm22.8 96h82.5c9 44.4 24.1 82.2 43.2 109.1-55-18.2-100.2-57.9-125.7-109.1zM248 472c-26.9 0-61.4-44.1-78.1-120h156.2c-16.7 75.9-51.2 120-78.1 120zm67.5-10.9c19-26.8 34.2-64.6 43.2-109.1h82.5c-25.5 51.2-70.7 90.9-125.7 109.1zM363.8 320c2.6-20.5 4.2-41.8 4.2-64s-1.5-43.5-4.2-64h90.5c6.3 20.2 9.7 41.7 9.7 64s-3.4 43.8-9.7 64h-90.5z"
        />
      </svg>
    </template>

    <CDropdownItem
      class="d-block"
      v-for="lang in languages"
      :key="lang.title"
      @click="switchLocale(lang.language)"
      :active="lang.language === language.language"
    >
      {{ lang.title }}
    </CDropdownItem>
  </CDropdown>
</template>

<script>
import { setCulture } from "@syncfusion/ej2-base";
const languages = [
  { language: "it", title: "Italiano" },
  { language: "en", title: "English" },
  { language: "de", title: "Deutsch" },
  // { anguage: "fr", title: "Français" },
];

const getLanguage = (locale) => {
  const tmp = languages.filter((l) => l.language === locale);
  return tmp.length ? tmp[0] : languages[0];
};

export default {
  name: "UiHeaderUserLang",

  data() {
    return {
      languages: languages,
      language: getLanguage(this.$i18n.locale),
    };
  },
  methods: {
    switchLocale(locale) {
      if (this.$i18n.locale !== locale) {
        this.$i18n.locale = locale;
        this.language = getLanguage(this.$i18n.locale);
        setCulture(locale)
      }
    },
  },
};
</script>
